import { render, staticRenderFns } from "./PreContentLoader.vue?vue&type=template&id=2d796c37&"
import script from "./PreContentLoader.vue?vue&type=script&lang=js&"
export * from "./PreContentLoader.vue?vue&type=script&lang=js&"
import style0 from "./PreContentLoader.vue?vue&type=style&index=0&id=2d796c37&prod&lang=stylus&module=attr&"




function injectStyles (context) {
  
  this["attr"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports