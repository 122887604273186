// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".css__q\\+tyl0{bottom:0;left:0;overflow-y:hidden;position:fixed;right:0;top:0;z-index:-9910}.css__q\\+tyl0,.css__IVDcMY{background-color:var(--theme_white)}.css__8nA-3o{left:0;margin:0 auto;position:absolute;right:0;top:50%;width:200px}.css__8nA-3o,.css__8nA-3o span{text-align:center;transform:translateY(-50%)}.css__8nA-3o span{color:var(--theme_quatermary);font-family:var(--poppins);font-size:24px;font-weight:var(--bold);z-index:2}.css__wurcCS{align-items:center;display:flex;justify-content:center;margin:0 -5%;overflow:hidden;padding:0;position:relative}.css__tma1mM{animation:css__KSkHqo .8s ease-in-out infinite alternate;animation-delay:.4s;background-color:var(--theme_primary);border-radius:5px;color:var(--theme_primary);height:8px;position:relative;width:8px}.css__tma1mM:after,.css__tma1mM:before{content:\"\";display:inline-block;position:absolute;top:0}.css__tma1mM:before{animation:css__KSkHqo .8s infinite alternate;animation-delay:0s;left:-18px}.css__tma1mM:after,.css__tma1mM:before{background-color:var(--theme_primary);border-radius:5px;color:var(--theme_primary);height:8px;width:8px}.css__tma1mM:after{animation:css__KSkHqo .8s infinite alternate;animation-delay:.8s;left:18px}@keyframes css__KSkHqo{0%{background-color:var(--theme_primary)}50%,to{background-color:var(--theme_secondary)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "css__q+tyl0",
	"loader--indicator": "css__IVDcMY",
	"loader__wrapper": "css__8nA-3o",
	"loader__progress": "css__wurcCS",
	"loader__dot": "css__tma1mM",
	"dot-flashing": "css__KSkHqo"
};
module.exports = ___CSS_LOADER_EXPORT___;
